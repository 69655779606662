@import "@fortawesome/fontawesome-free/css/all.css";

body {
  font-family: 'Poppins', sans-serif;
}

/* In modal z-index*/
.modal-backdrop {
    z-index: 1000 !important;
  }
:host {
  position: fixed;
  background-color: #9b0408;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 300px; /* Set a fixed width or adjust as needed */
  margin-top: 20px; /* Add some top margin if needed */
}

